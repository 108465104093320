var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-toolbar',{staticClass:"secondary",attrs:{"dark":"","flat":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_vm._v("发行说明")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-4",attrs:{"flat":"","dense":"","hide-details":"","label":"搜索产品名称关键字...","prepend-inner-icon":"search","solo-inverted":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchItem.apply(null, arguments)}},model:{value:(_vm.query.key),callback:function ($$v) {_vm.$set(_vm.query, "key", $$v)},expression:"query.key"}})],1),_c('v-alert',{staticClass:"ma-2",attrs:{"dense":"","type":"info","dismissible":""}},[_vm._v(" ESP版本更新说明。由开发商进行填写和编辑。 ")]),_c('v-data-table',{attrs:{"headers":_vm.headerTable,"items":_vm.releaseList,"options":_vm.optionsTable,"server-items-length":_vm.releaseCount,"loading":_vm.releaseLoading,"loading-text":"数据读取中... 请稍后","footer-props":{'items-per-page-options': [5, 10,15, 30, 50, 100]},"item-key":"_id","transition":""},on:{"update:options":function($event){_vm.optionsTable=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("更新记录")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":"","color":"secondary"},on:{"click":function($event){return _vm.editItem()}}},[_vm._v("发布版本")])],1)]},proxy:true},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_c('core-version',{attrs:{"id":item._id}},[_vm._v("v"+_vm._s(item.version))])],1)]}},{key:"item.publish_date",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.publish_date)))])]}},{key:"item.publisher",fn:function(ref){
var item = ref.item;
return [(item.publisher)?_c('td',{attrs:{"nowrap":"true"}},[_c('widgets-employee-dialogs',{staticClass:"v-list-item--link",attrs:{"account":item.publisher._id}},[_vm._v(_vm._s(item.publisher.personal ? item.publisher.personal.name : '*未设置姓名'))])],1):_vm._e()]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [(item.creator)?_c('td',{attrs:{"nowrap":"true"}},[_c('widgets-employee-dialogs',{staticClass:"v-list-item--link",attrs:{"account":item.creator._id}},[_vm._v(_vm._s(item.creator.personal ? item.creator.personal.name : '*未设置姓名'))])],1):_vm._e()]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("edit")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.getDataList()}}},[_vm._v("刷新")])]},proxy:true}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"650px","retain-focus":false,"eager":""},model:{value:(_vm.dlgEdit),callback:function ($$v) {_vm.dlgEdit=$$v},expression:"dlgEdit"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.editTitle))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"type":"text","prefix":"v","label":"版本号","hint":"请输入版本标示","outlined":"","dense":""},model:{value:(_vm.editedItem.version),callback:function ($$v) {_vm.$set(_vm.editedItem, "version", $$v)},expression:"editedItem.version"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"发布日期","hint":"请选择版本的发布日期","readonly":"","outlined":"","dense":""},model:{value:(_vm.editedItem.publish_date),callback:function ($$v) {_vm.$set(_vm.editedItem, "publish_date", $$v)},expression:"editedItem.publish_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataTimeMenu),callback:function ($$v) {_vm.dataTimeMenu=$$v},expression:"dataTimeMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.dataTimeMenu = false}},model:{value:(_vm.editedItem.publish_date),callback:function ($$v) {_vm.$set(_vm.editedItem, "publish_date", $$v)},expression:"editedItem.publish_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.activeEdit)?_c('editor',{attrs:{"init":_vm.editorInit,"id":'release_'+ _vm.editedItem._id},model:{value:(_vm.editedItem.context),callback:function ($$v) {_vm.$set(_vm.editedItem, "context", $$v)},expression:"editedItem.context"}}):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"secondary","text":"","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v("保存")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dlgConfirm),callback:function ($$v) {_vm.dlgConfirm=$$v},expression:"dlgConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("确定要删除指定项目？")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v("取消")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("确认删除")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }