var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-toolbar',{staticClass:"secondary",attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("知识库")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-4",attrs:{"flat":"","dense":"","hide-details":"","label":"搜索知识标题或标签关键字...","prepend-inner-icon":"search","solo-inverted":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchKey()}},model:{value:(_vm.query.key),callback:function ($$v) {_vm.$set(_vm.query, "key", $$v)},expression:"query.key"}})],1),_c('v-alert',{staticClass:"ma-2",attrs:{"dense":"","type":"info","dismissible":""}},[_vm._v(" 知识库是编辑和发布知识的模块。企业端可以通过检索查询相关知识，同时可以按知识目录提交咨询请求。知识目录的维护在系统设置中完成。 ")]),(_vm.showAllKB)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[_c('v-data-table',{attrs:{"headers":_vm.headersAll,"items":_vm.knowledgeList,"options":_vm.optionsItem,"server-items-length":_vm.knowledgeCount,"loading":_vm.knowledgeLoading,"loading-text":"数据读取中... 请稍后","footer-props":{'items-per-page-options': [5, 10,15, 30, 50, 100]},"item-key":"_id","return-object":"false","transition":""},on:{"update:options":function($event){_vm.optionsItem=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-truncate",attrs:{"width":"250px"}},[_c('widgets-knowledge-dialogs',{key:item._id,attrs:{"getId":item._id},on:{"Close":function($event){return _vm.reloadPage()},"Update":function($event){return _vm.getList(_vm.query)}}},[(item.isTop)?_c('v-icon',{attrs:{"small":""}},[_vm._v("vertical_align_top")]):_vm._e(),(item.status === 0)?_c('v-icon',{attrs:{"small":""}},[_vm._v("block")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("check_circle_outline")]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)]}},{key:"item.editor",fn:function(ref){
var item = ref.item;
return [(item.editor)?_c('td',{attrs:{"nowrap":"true","width":"80px"}},[_c('widgets-employee-dialogs',{staticClass:"v-list-item--link",attrs:{"account":item.editor._id}},[_vm._v(_vm._s(item.editor.personal ? item.editor.personal.name : '*未设置姓名'))])],1):_vm._e()]}},{key:"item.isVideo",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true","width":"180px"}},[_vm._v(_vm._s(item.isVideo ? '视频' : '图文'))])]}},{key:"item.catalog",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true","width":"180px"}},[_vm._v(_vm._s(item.catalog.name))])]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true","width":"80px"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.updateTime)))])]}}],null,false,3026516899)})],1)],1)],1):_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-treeview',{staticClass:"text-button",attrs:{"active":_vm.activeCatalog,"items":_vm.KBCatalogList,"loading-icon":_vm.KBCatalogLoading ? 'hourglass_top' : '',"color":"secondary","open":_vm.deptOpenTree,"return-object":"","activatable":"","open-on-click":"","transition":"","shaped":"","hoverable":"","dense":""},on:{"update:active":function($event){_vm.activeCatalog=$event},"update:open":function($event){_vm.deptOpenTree=$event}},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.countKnowledge(item))+" ")]}}])})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',[_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[(!_vm.activeCatalog.length)?_c('div',{staticClass:"title text--lighten-1 font-weight-light",staticStyle:{"align-self":"center"}},[_vm._v(" 请选择知识目录 ")]):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.knowledgeList,"options":_vm.optionsItem,"server-items-length":_vm.knowledgeCount,"loading":_vm.knowledgeLoading,"loading-text":"数据读取中... 请稍后","footer-props":{'items-per-page-options': [5, 10,15, 30, 50, 100]},"item-key":"_id","return-object":"false","transition":""},on:{"update:options":function($event){_vm.optionsItem=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.activeCatalog.length)?_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-4"},[_vm._v("description")]),_vm._v("知识列表")],1):_vm._e(),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.currentUser.hasAdmin.includes('dealer') || _vm.checkCreatePower(_vm.activeCatalog, _vm.currentUser))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" 创建新知识 ")],1)]}}],null,false,3842750709)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.createKnowledge(_vm.activeCatalog, 'text')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("description")])],1),_c('v-list-item-title',[_vm._v("增加图文知识")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.createKnowledge(_vm.activeCatalog, 'video')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("smart_display")])],1),_c('v-list-item-title',[_vm._v("增加视频百科")])],1)],1)],1):_vm._e()],1)]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-truncate",style:(_vm.headerScale ? '' : 'max-width: 125px;')},[_c('widgets-knowledge-dialogs',{key:item._id,attrs:{"getId":item._id},on:{"Close":function($event){return _vm.reloadPage()},"Update":function($event){return _vm.getList(_vm.query)}}},[(item.isTop)?_c('v-icon',{attrs:{"small":""}},[_vm._v("vertical_align_top")]):_vm._e(),(item.status === 0)?_c('v-icon',{attrs:{"small":""}},[_vm._v("block")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("check_circle_outline")]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)]}},{key:"header.scale",fn:function(){return [_c('v-icon',{staticClass:"mx-2",attrs:{"size":"18"},on:{"click":function($event){_vm.headerScale = !_vm.headerScale}}},[_vm._v(_vm._s(_vm.headerScale ? 'keyboard_backspace' : 'keyboard_tab'))])]},proxy:true},{key:"item.editor",fn:function(ref){
var item = ref.item;
return [(item.editor)?_c('td',{attrs:{"nowrap":"true"}},[_c('widgets-employee-dialogs',{staticClass:"v-list-item--link",attrs:{"account":item.editor._id}},[_vm._v(_vm._s(item.editor.personal ? item.editor.personal.name : '*未设置姓名'))])],1):_vm._e()]}},{key:"item.isVideo",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(item.isVideo ? '视频' : '图文'))])]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[_vm._v(_vm._s(_vm._f("formatDate")(item.updateTime)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"nowrap":"true"}},[(_vm.currentUser.hasAdmin.includes('dealer') || _vm.checkAuditPower(_vm.activeCatalog, _vm.currentUser))?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item._id, item.isVideo)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit_note")])],1),_c('v-list-item-title',[_vm._v("编辑")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete_outline")])],1),_c('v-list-item-title',[_vm._v("删除")])],1),(!item.isTop)?_c('v-list-item',{on:{"click":function($event){return _vm.setTop(item, true)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("vertical_align_top")])],1),_c('v-list-item-title',[_vm._v("置顶")])],1):_c('v-list-item',{on:{"click":function($event){return _vm.setTop(item, false)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("vertical_align_bottom")])],1),_c('v-list-item-title',[_vm._v("取消置顶")])],1),(item.status === 0)?_c('v-list-item',{on:{"click":function($event){return _vm.setAuth(item, 1)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("check_circle_outline")])],1),_c('v-list-item-title',[_vm._v("发布")])],1):_c('v-list-item',{on:{"click":function($event){return _vm.setAuth(item, 0)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("block")])],1),_c('v-list-item-title',[_vm._v("下架")])],1)],1)],1):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }