<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>企业管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_enterprise')"
          dark
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索企业名称"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
        {{enterpriseCount}}家企业
        <v-btn icon @click.stop="doAction('filter_enterprise')">
          <v-icon>filter_list</v-icon>
        </v-btn>
        <v-btn icon @click="doAction('create_enterprise')">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-alert dense type="info" class="ma-2" dismissible>
      {{enterpriseList.length ? '经销商全部人员均可以浏览企业列表，但编辑和审核需要企业管理权限，可以联系平台负责人获取权限。' : '没有找到任何企业信息'}}
    </v-alert>
    <v-row class="fill-height overflow-y-auto" v-if="enterpriseList.length">
      <v-col lg="4" md="4" sm="6" cols="12" v-for="(enterprise, index) in enterpriseList" :key="enterprise._id">
          <v-card outlined hover :ripple="false" :color="enterprise.isBlock ? 'red lighten-4' : ''">
            <v-card-title><span class="d-inline-block text-truncate">{{enterprise.name}}</span></v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon small>contact_phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>{{enterprise.contact_name}} - {{enterprise.contact_phone}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon small>room</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>{{enterprise.city}} - {{enterprise.address}}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-actions>
              <v-badge :color="enterprise.isBlock ? 'red' : 'green'" inline dot></v-badge><v-subheader>{{enterprise.createTime | formatDate}} 创建</v-subheader>
              <v-spacer></v-spacer>
              <v-btn icon @click="openInventory(index)" v-if="enterprise.inventory_warning && enterprise.inventory_warning.length">
                <v-icon v-if="enterprise.inventory_warning.find(item => item.minimum > item.count)" color="red">battery_charging_full</v-icon>
                <v-icon v-else>battery_full</v-icon>
              </v-btn>
              <v-btn icon @click="openService(index)" v-if="enterprise.service_catalog && enterprise.service_catalog.length">
                <v-icon v-if="enterprise.service_catalog.find(item => diffDays(item.end_time) > 0)">verified</v-icon>
                <v-icon v-else-if="enterprise.service_catalog.find(item => diffDays(item.end_time) > -168)" color="red">verified</v-icon>
                <v-icon v-else-if="enterprise.service_catalog.find(item => diffDays(item.end_time) > -720)" color="orange">verified</v-icon>
                <v-icon v-else>verified</v-icon>
              </v-btn>
              <v-btn icon @click="doAction('open_enterprise', enterprise)"><v-icon>more_horiz</v-icon></v-btn>
            </v-card-actions>
            <v-expand-transition>
              <v-card v-if="cardService[index]" class="transition-fast-in-fast-out v-card--reveal overflow-y-auto" style="height: 100%;">
                <v-card-title>服务协议<v-spacer></v-spacer><v-icon @click="closeService(index)">close</v-icon></v-card-title>
                <v-card-text>
                  <v-list subheader dense two-line>
                    <v-list-item v-for="(service, i) in enterprise.service_catalog" :key="'service_'+ i">
                      <v-list-item-icon>
                        <v-icon v-if="diffDays(service.end_time) > 0">remove_circle</v-icon>
                        <v-icon v-else-if="diffDays(service.end_time) > -168" color="red">error</v-icon>
                        <v-icon v-else-if="diffDays(service.end_time) > -720" color="orange">circle_notifications</v-icon>
                        <v-icon v-else>check_circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-for="catalog in service.catalog" :key="catalog._id">
                            {{catalog.name}}
                          </span></v-list-item-title>
                        <v-list-item-subtitle>
                          {{ service.end_time | formatDate }} - {{ service.end_time | fromDate }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeService(index)">关闭</v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
            <v-expand-transition>
              <v-card v-if="cardInventory[index]" class="transition-fast-in-fast-out v-card--reveal overflow-y-auto" style="height: 100%;">
                <v-card-title>库存设备<v-spacer></v-spacer><v-icon @click="closeInventory(index)">close</v-icon></v-card-title>
                <v-card-text>
                  <v-list subheader dense two-line>
                    <v-list-item v-for="(inventory, i) in enterprise.inventory_warning" :key="'inventory_'+ i">
                      <v-list-item-icon>
                        <v-icon v-if="inventory.minimum < inventory.count">check_circle</v-icon>
                        <v-icon v-else color="error">error</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ inventory.product.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span :class="inventory.minimum >= inventory.count ? 'error--text' : ''">库存：{{inventory.count}}</span>
                          - 预警：{{inventory.minimum}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeInventory(index)">关闭</v-btn>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular
          v-if="this.query.page * this.query.limit < enterpriseCount"
          indeterminate
          color="secondary"
          width="2"
          v-intersect="{
          handler: infiniteScrolling,
          options: {
              threshold: [1.0]
          }
        }"
        ></v-progress-circular>
        <span v-else class="title font-weight-light">已展示{{enterpriseCount}}家企业信息，没有更多数据了。</span>
      </v-col>
    </v-row>
    <v-row v-else>
      <span class="bg"></span>
    </v-row>
    <v-fab-transition>
      <v-btn
        class="transition-swing"
        v-scroll="onScroll"
        v-show="fab"
        color="secondary"
        fab
        dark
        fixed
        bottom
        right
        large
        @click="toTop"
        style="z-index: 6"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-navigation-drawer v-model="filterDrawer" app temporary bottom right style="z-index: 999;">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">企业类型</h2>
        <v-chip-group v-model="query.corp_type" column multiple>
          <v-chip v-for="typef in corpTypedef" :key="typef.code" :value="typef.code" filter label outlined>{{typef.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">企业状态</h2>
        <v-chip-group v-model="query.isBlock" column multiple>
          <v-chip :value="false" filter label outlined>正常运营</v-chip>
          <v-chip :value="true" filter label outlined>暂停运营</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">包含服务</h2>
        <v-chip-group v-if="catalogList.length" v-model="query.service_catalog" column multiple>
          <v-chip v-for="catalog in flatGroupCatalog(catalogList).filter(c => c['group'])" :key="catalog._id" :value="catalog._id" filter label outlined>{{catalog.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">服务到期</h2>
        <v-chip-group v-model="query.end_time" column>
          <v-chip v-for="datetiem in expiryDate" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doAction('clear_filter')">清空</v-btn>
          <v-btn color="secondary" text @click="doAction('set_filter')">确认</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
      <widgets-enterprise-dialogs :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
    </v-dialog>
  </v-container>
</template>
<script>
import Util from '@/common/util';
import { mapGetters } from "vuex";
import moment from "moment";
import store from "@/store";
import {
  FETCH_ENTERPRISE_LIST,
  FETCH_CATALOG_LIST,
} from "@/store/actions.type";
export default {
  data: () => ({
    enterpriseList: [],
    filterDrawer: null,
    fab: false,
    dlgInfo: false,
    query: {
      key: '',
      corp_type: [],
      isBlock: [],
      service_catalog: [],
      end_time: '',
      page: 1,
      limit: 10,
    },
    defaultQuery: {
      key: '',
      corp_type: [],
      isBlock: [],
      service_catalog: [],
      end_time: '',
      page: 1,
      limit: 10,
    },
    activeId: null,
    cardService: [],
    cardInventory: [],
  }),
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_CATALOG_LIST),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters(["currentUser", "enterpriseLoading", "enterpriseCount", "catalogList"]),
    corpTypedef () {
      return Util.categories('corpType')
    },
    expiryDate () {
      return Util.categories('expiry')
    }
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query)
    this.getList()
  },
  created() {},
  methods: {
    getList() {
      let strQuery = '';
      if (this.query.key) strQuery += '&key='+ this.query.key
      if (this.query.isBlock.length && this.query.isBlock.length <= 1) strQuery += '&isBlock='+ this.query.isBlock
      if (this.query.corp_type.length) strQuery += '&corp_type='+ this.query.corp_type
      if (this.query.service_catalog.length) strQuery += '&service_catalog='+ this.query.service_catalog
      if (this.query.end_time) strQuery += '&end_time='+ this.query.end_time
      if (this.query.page) strQuery += '&page='+ this.query.page
      if (this.query.limit) strQuery += '&limit='+ this.query.limit
      store.dispatch(FETCH_ENTERPRISE_LIST, strQuery)
        .then((data) => {
          this.enterpriseList.push(...data.enterprise)
          this.cardService = Array(data.enterprise.length).fill(false)
          this.cardInventory = Array(data.enterprise.length).fill(false)
        })
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'create_enterprise': {
          // this.$store.commit(SET_ERROR, {msg: '不能创建'});
          this.$router.push({path:'/dealer/enterprise/edit/0'})
          break
        }
        case 'open_enterprise': {
          this.activeId = item._id
          this.dlgInfo = true
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'search_enterprise': {
          this.query.page = 1;
          this.query.limit = 20;
          this.enterpriseList = [];
          this.cardService = [];
          this.cardInventory = [];
          this.getList();
          break
        }
        case 'filter_enterprise': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'set_filter': {
          this.query.page = 1;
          this.query.limit = 20;
          this.enterpriseList = [];
          this.cardService = [];
          this.cardInventory = [];
          this.getList();
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery)
          this.enterpriseList = []
          this.cardService = []
          this.cardInventory = []
          this.getList()
          this.filterDrawer = !this.filterDrawer
          break
        }
      }
    },
    infiniteScrolling() {
      this.query.page++;
      this.getList();
    },
    onScroll () {
      if (typeof window === 'undefined') return
      const top = (
        window.pageYOffset ||
        document.documentElement.offsetTop ||
        0
      )
      this.fab = top > 300
    },
    openService(index) {
      this.cardService[index] = true
      this.$forceUpdate()

    },
    closeService(index) {
      this.cardService[index] = false
      this.$forceUpdate()
    },
    openInventory(index) {
      this.cardInventory[index] = true
      this.$forceUpdate()

    },
    closeInventory(index) {
      this.cardInventory[index] = false
      this.$forceUpdate()
    },
    toTop () {
      if (this.$route.hash) {
        this.$router.push({ hash: '' })
      }
      this.$vuetify.goTo(0)
    },
    unique(arrDuplicate) {
      if (arrDuplicate.length) {
        let arrService = [];
        arrDuplicate.forEach(service => {
          service.catalog.forEach(catalog => {
            arrService.push({...catalog, end_time: service.end_time})
          })
        })
        return arrService
      }
    },
    diffDays(endTime) {
      let nowTime = moment();
      return nowTime.diff(endTime, 'hours')
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, group: groupItem.name })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
  }
};
</script>
<style scoped>
  .hideText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    background: url( '/static/empty_data.png') no-repeat center center;
    background-size: contain;
    transform: scale(0.15);
  }
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
</style>
