<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{title_page()}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
            <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
            <v-img
              :src="editItemData.profile_image ? ossURL +'/'+ editItemData.profile_image : '/static/error/empty_state.svg'"
              class="white--text align-end text-right"
              dark
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="375px"
              :aspect-ratio="24/9">
              <v-card-title class="text-center justify-center">
                <v-img
                  :src="editItemData.logo_image ? ossURL +'/'+ editItemData.logo_image : '/static/error/empty_state.svg'"
                  contain max-width="200" max-height="155">
                </v-img>
              </v-card-title>
              <v-card-title class="text-center justify-center">
                {{ editItemData.sub_title }}
              </v-card-title>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn text @click="editItem('upload_logo', editItemData)"><v-icon left>add_photo_alternate</v-icon>上传LOGO</v-btn>
                <v-divider vertical inset></v-divider>
                <v-btn text @click="editItem('upload_profile', editItemData)"><v-icon left>add_photo_alternate</v-icon>上传背景图</v-btn>
              </v-card-title>
            </v-img>
          </v-col>
          <v-col cols="5">
            <v-expansion-panels v-if="editItemData">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <v-row no-gutters>
                      <v-col cols="4">管理人员</v-col>
                      <v-col cols="8">
                        <v-fade-transition leave-absolute>
                          <span v-if="open" key="0">单击右侧按钮移出管理权限</span>
                          <span v-else key="1">{{adminList.length}}人</span>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense two-line>
                    <v-list-item v-for="(item, i) in adminList" :key="i">
                      <v-list-item-icon>
                        <v-icon>assignment_ind</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.personal.name }} - {{item.username}}</v-list-item-title>
                        <v-list-item-subtitle>状态：{{ item.isBlock ? '停用' : item.status === 1 ? '正常' : '停用' }} | 手机号码：{{item.phone}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon @click="editItem('remove_admin', editItemData, item)">remove_circle</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title align="end">
                          <v-btn text @click="editItem('add_admin', editItemData, '创建管理员')">
                            <v-icon left>add</v-icon>创建管理人员
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-list subheader dense two-line v-if="editItemData">
              <v-list-item @click="editItem('to_employer', editItemData, '查看员工')">
                <v-list-item-icon>
                  <v-icon>groups</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>员工总数：{{editItemData.employee_count}}</v-list-item-title>
                  <v-list-item-subtitle>查看所有员工</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>launch</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="editItem('to_asset', editItemData, '查看设备')">
                <v-list-item-icon>
                  <v-icon>devices</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>设备总数：{{editItemData.asset_count}}</v-list-item-title>
                  <v-list-item-subtitle>查看所有设备</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>launch</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-expansion-panels v-if="editItemData">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-icon color="error" v-if="editItemData.inventory_warning && editItemData.inventory_warning.find(item => item.minimum >= item.count)">error</v-icon>
                  </template>
                  <v-row no-gutters>
                    <v-col cols="4">库存预警</v-col>
                    <v-col cols="8" class="text--secondary">
                      <v-fade-transition leave-absolute>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list subheader dense two-line>
                    <v-list-item v-for="(item, i) in editItemData.inventory_warning" :key="i">
                      <v-list-item-icon>
                        <v-icon v-if="item.minimum < item.count">check_circle</v-icon>
                        <v-icon v-else color="error">error</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.product.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span :class="item.minimum >= item.count ? 'error--text' : ''">库存：{{item.count}}</span>
                          - 预警：{{item.minimum}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon class="mx-2" @click="editItem('edit_warning', editItemData, item)">create</v-icon>
                        <v-icon @click="editItem('remove_warning', editItemData, item)">remove_circle</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title align="end">
                          <v-btn text @click="editItem('add_inventory', editItemData, '创建库存预警')">
                            <v-icon left>add</v-icon>增加预警配置
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="7">
            <v-expansion-panels v-if="editItemData">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <v-col cols="4">企业信息</v-col>
                    <v-col cols="8">
                      <v-fade-transition leave-absolute>
                        <span>{{ editItemData.name }}</span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list subheader dense two-line>
                    <v-subheader>企业信息</v-subheader>
                    <v-list-item @click="editItem('enterprise_name', editItemData, '企业名称')">
                      <v-list-item-content>
                        <v-list-item-title>{{ editItemData.name }}</v-list-item-title>
                        <v-list-item-subtitle v-if="editItemData.creater">状态：{{ editItemData.isBlock ? '暂停运营' : editItemData.status === 1 ? '正常' : '待审批' }} | <widgets-employee-dialogs :account="editItemData.creater._id">{{editItemData.creater.personal.name ? editItemData.creater.personal.name : '*未设置姓名'}}</widgets-employee-dialogs>创建于 {{ editItemData.createTime | formatTime }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>pin</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title id="copyId">{{ editItemData._id }}</v-list-item-title>
                        <v-list-item-subtitle>企业编码是企业在ESP系统唯一标识，不能修改。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <widgets-copy-btn :target="copyID"/>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>vpn_key</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title id="copyTarget">{{ editItemData.invite_code }}</v-list-item-title>
                        <v-list-item-subtitle>企业邀请码作为企业员工注册时填写依据，不能修改。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <widgets-copy-btn :target="copyCode"/>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('corp_type', editItemData, '企业类型')">
                      <v-list-item-icon>
                        <v-icon>category</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ editItemData.corp_type && getTypeText(editItemData.corp_type).name }} </v-list-item-title>
                        <v-list-item-subtitle>信用代码：{{ editItemData.license_key }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('industry', editItemData, '所属行业')">
                      <v-list-item-icon>
                        <v-icon>engineering</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ editItemData.industry && editItemData.industry.name }} </v-list-item-title>
                        <v-list-item-subtitle>企业所属行业</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('name_code', editItemData, '企业简称')">
                      <v-list-item-icon>
                        <v-icon>closed_caption</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ editItemData.name_code }}</v-list-item-title>
                        <v-list-item-subtitle>企业代码，用于第三方系统如ERP、CRM、S1等客户标识。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('sub_title', editItemData, '企业理念')">
                      <v-list-item-icon>
                        <v-icon>flag</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ editItemData.sub_title }}</v-list-item-title>
                        <v-list-item-subtitle>企业理念：企业宣传口号，文化理念</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('contact_name', editItemData, '联系方式')">
                      <v-list-item-icon>
                        <v-icon>contact_phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>联系人：{{ editItemData.contact_name }}</v-list-item-title>
                        <v-list-item-subtitle>联系电话：{{ editItemData.contact_phone }} 公开电话：{{ editItemData.public_phone }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('address', editItemData, '企业地址')">
                      <v-list-item-icon>
                        <v-icon>room</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ editItemData.province }} - {{ editItemData.city }}</v-list-item-title>
                        <v-list-item-subtitle>详细地址：{{ editItemData.address }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('branch', editItemData, '下属企业')">
                      <v-list-item-icon>
                        <v-icon>account_tree</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ editItemData.branch }}</v-list-item-title>
                        <v-list-item-subtitle>下属企业，便于企业区分不同分支机构的设备和流程请求</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('notice_email', editItemData, '通知方式')">
                      <v-list-item-icon>
                        <v-icon>notifications</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>接收通知：{{ editItemData.notice_email }} - {{ editItemData.notice_phone }}</v-list-item-title>
                        <v-list-item-subtitle>企业端接收服务状态变更通知的终端，清空不再接收通知</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('account_managers', editItemData, '客户经理')">
                      <v-list-item-icon>
                        <v-icon>engineering</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="editItemData.account_managers && editItemData.account_managers.length">
                          <widgets-employee-dialogs v-for="managers in editItemData.account_managers" :key="managers._id" :account="managers._id" divider="- ">
                            {{managers.personal.name ? managers.personal.name : '*未设置姓名'}}
                          </widgets-employee-dialogs>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          无
                        </v-list-item-title>
                        <v-list-item-subtitle>客户负责人。可以维护和修改客户资料</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('account_order', editItemData, '订单处理人')">
                      <v-list-item-icon>
                        <v-icon>manage_accounts</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="editItemData.account_order">
                          <widgets-employee-dialogs :account="editItemData.account_order._id">
                            {{editItemData.account_order.personal.name ? editItemData.account_order.personal.name : '*未设置姓名'}}
                          </widgets-employee-dialogs>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          无
                        </v-list-item-title>
                        <v-list-item-subtitle>订单负责人。可以查看并处理客户请求的采购或租赁订单。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('account_sales', editItemData, '订单销售人')">
                      <v-list-item-icon>
                        <v-icon>hail</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="editItemData.account_sales">
                          <widgets-employee-dialogs :account="editItemData.account_sales._id">
                            {{editItemData.account_sales.personal.name ? editItemData.account_sales.personal.name : '*未设置姓名'}}
                          </widgets-employee-dialogs>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          无
                        </v-list-item-title>
                        <v-list-item-subtitle>订单销售人。可以查看客户请求的采购或租赁订单。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('additional', editItemData, '附加信息')">
                      <v-list-item-icon>
                        <v-icon>bookmark_add</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>ABM：{{ editItemData.additional ? editItemData.additional.abm : '-' }} | ACE: {{ editItemData.additional ? editItemData.additional.ace : '-' }}  | ESC Apple ID: {{ editItemData.additional ? editItemData.additional.esc : '-' }} </v-list-item-title>
                        <v-list-item-subtitle>企业在日常服务中会用到的一些附加信息</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="editItem('notice_email', editItemData, '服务限制')">
                      <v-list-item-icon>
                        <v-icon>vertical_align_top</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="editItemData.limit">最大员工数：{{ editItemData.limit.employees }} - 最大设备数量：{{ editItemData.limit.assets }}</v-list-item-title>
                        <v-list-item-subtitle>权限调整后仅对新业务产生影响，已经录入的数据和业务不受影响。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>how_to_reg</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>员工注册：{{ editItemData.self_register ? '允许' : '禁止' }}</v-list-item-title>
                        <v-list-item-subtitle>企业员工可以通过邀请码在ESP平台进行自助注册。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch v-model="editItemData.self_register" @change="editItem('self_register', editItemData)"></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>devices</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>设备管理：{{ editItemData.self_asset ? '允许' : '禁止' }}</v-list-item-title>
                        <v-list-item-subtitle>企业具备资产管理权限，可自行修改设备信息。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch v-model="editItemData.self_asset" @change="editItem('self_asset', editItemData)"></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('mail_suffix', editItemData, '邮箱后缀')">
                      <v-list-item-icon>
                        <v-icon>email</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>邮箱后缀：<v-chip class="mx-2" label small v-for="(label, index) in editItemData.mail_suffix" :key="index">{{ label }}</v-chip></v-list-item-title>
                        <v-list-item-subtitle>提交维修服务时需要通过此后缀的邮箱进行验证码验证。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="editItem('receipt_info', editItemData, '发票抬头')">
                      <v-list-item-icon>
                        <v-icon>receipt_long</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>发票抬头：</v-list-item-title>
                        <v-list-item-subtitle>{{ editItemData.invoice_info ? editItemData.invoice_info.title + ' ' + editItemData.invoice_info.license_code : '无' }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="editItem('api_callback', editItemData, '集成接口')">
                      <v-list-item-icon>
                        <v-icon>api</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="editItemData.api"></v-list-item-title>
                        <v-list-item-subtitle>与第三方系统进行集成配置接口及接口回调信息。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>hub</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>对接公贝：{{ editItemData.allow_gongbei ? '允许' : '禁止' }}</v-list-item-title>
                        <v-list-item-subtitle>允许该企业在公贝系统创建和查阅ESP工单。</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch v-model="editItemData.allow_gongbei" @change="editItem('allow_gongbei', editItemData)"></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col cols="12">
            <v-data-table
              :headers="service.headers"
              :items="serviceList"
              :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
              sort-by="end_time"
              sort-desc
              dense
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>服务协议</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" class="mb-2" depressed @click="editItem('create_service', editItemData)">新增服务</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem('edit_service', editItemData, item)">edit</v-icon>
                <v-icon small class="mr-2" @click="editItem('delete_service', editItemData, item)">delete</v-icon>
              </template>
              <template v-slot:item.catalog="{ item }">
                {{item.catalog.map(i => i.name)}}
              </template>
              <template v-slot:item.branch="{ item }">
                {{item.branch.map(i => i.name)}}
              </template>
              <template v-slot:item.start_time="{ item }">
                {{ item.start_time | formatDate }}
              </template>
              <template v-slot:item.end_time="{ item }">
                <span v-if="diffDays(item.end_time) > 0" class="text-decoration-line-through">{{ item.end_time | formatDate }} - <span class="overline">{{ item.end_time | fromDate }}</span></span>
                <span v-else-if="diffDays(item.end_time) > -24" class="red--text">{{ item.end_time | formatDate }} - <span class="overline">{{ item.end_time | fromDate }}</span></span>
                <span v-else-if="diffDays(item.end_time) > -168" class="orange--text">{{ item.end_time | formatDate }} - <span class="overline">{{ item.end_time | fromDate }}</span></span>
                <span v-else>{{ item.end_time | formatDate }} - <span class="overline">{{ item.end_time | fromDate }}</span></span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row justify="space-between" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Lease_Product') || currentUser.permission.includes('Lease_Admin') || currentUser.permission.includes('Lease_Audit')">
          <v-col cols="12">
            <v-alert type="info" icon="credit_score" prominent>
              <v-row align="center">
                <v-col class="grow">
                  授信年度：<strong>{{ editItemData.credit_line_year }}</strong>年 ｜ 授信额度（系统）：<strong>{{ editItemData.credit_line_sys | formatMoneyUnitFloat }}</strong>元 | 授信额度（人工）：<strong>{{ editItemData.credit_line_adjust | formatMoneyUnitFloat }}</strong>元
                </v-col>
                <v-col class="shrink">
                  <v-btn text @click="editItem('adjust_earnings', editItemData, '调整授信额度')"><v-icon left small>create</v-icon>调整额度</v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <v-data-table
              :headers="earnings.headers"
              :items="earningsList"
              :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
              sort-by="years"
              sort-desc
              dense
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>年度财报</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" class="mb-2" depressed @click="editItem('create_earnings', editItemData)">新增财报</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.credit.assets="{ item }">
                {{item.credit.assets.$numberDecimal | formatMoneyUnitFloat}}
              </template>
              <template v-slot:item.credit.current_assets="{ item }">
                {{item.credit.current_assets.$numberDecimal | formatMoneyUnitFloat}}
              </template>
              <template v-slot:item.credit.equity="{ item }">
                {{item.credit.equity.$numberDecimal | formatMoneyUnitFloat}}
              </template>
              <template v-slot:item.credit.sales="{ item }">
                {{item.credit.sales.$numberDecimal | formatMoneyUnitFloat}}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem('edit_earnings', editItemData, item)">edit</v-icon>
                <v-icon small class="mr-2" @click="editItem('delete_earnings', editItemData, item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dlgEdit" persistent :scrollable="dlgTitle === '新增年度财报' || dlgTitle === '修改年度财报'" :max-width="dlgTitle === '新增年度财报' || dlgTitle === '修改年度财报' ? '750px' : '600px'">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-if="dlgTitle === '附加信息'"
              v-model="editItemData.additional.abm"
              type="text"
              label="ABM"
              hint="请输入企业的ABM信息"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '附加信息'"
              v-model="editItemData.additional.ace"
              type="text"
              label="ACE"
              hint="请输入企业的ACE信息"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '附加信息'"
              v-model="editItemData.additional.esc"
              type="text"
              label="Apple ID"
              hint="请输入企业的ESC Apple ID"
              outlined
              dense
            ></v-text-field>
            <v-combobox
              v-if="dlgTitle === '邮箱后缀'"
              v-model="editItemData.mail_suffix"
              label="邮箱后缀"
              hint="请输入邮箱@后域名，不包括@符号，如 hi@mdaas.com，输入mdaas.com 使用回车键可以输入多个地址。"
              deletable-chips
              multiple
              small-chips
              outlined
              dense
            >
            </v-combobox>
            <v-text-field
              v-if="dlgTitle === '企业名称' || dlgTitle === '创建新企业'"
              v-model="editItemData.name"
              :rules="[rules.required]"
              type="text"
              label="企业名称"
              hint="请输入企业的名称，该名称为全局唯一命名"
              outlined
              dense
            ></v-text-field>
            <v-autocomplete
              v-if="dlgTitle === '所属行业'"
              v-model="editItemData.industry"
              :items="rentIndustryList"
              item-text="name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              label="行业列表"
              hint="请选择企业所属行业"
            ></v-autocomplete>
            <v-text-field
              v-if="dlgTitle === '企业简称'"
              v-model="editItemData.name_code"
              type="text"
              label="企业简称"
              hint="请输入企业的简称，该简称来自S1系统。"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '企业理念'"
              v-model="editItemData.sub_title"
              type="text"
              label="企业理念"
              hint="请输入企业一句话介绍，如理念、愿景等信息。"
              outlined
              dense
            ></v-text-field>
            <v-autocomplete
              v-if="dlgTitle === '企业类型' || dlgTitle === '创建新企业'"
              autocomplete="off"
              v-model="editItemData.corp_type"
              :items="inputTypedef"
              :rules="[rules.selected]"
              label="企业类型"
              hint="请选择企业类型"
              item-text="name"
              item-value="code"
              outlined
              dense
            ></v-autocomplete>
            <v-text-field
              v-if="dlgTitle === '企业类型' || dlgTitle === '创建新企业'"
              v-model="editItemData.license_key"
              type="text"
              label="信用代码"
              hint="由工商统一颁发的营业执照号码"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '联系方式' || dlgTitle === '创建新企业'"
              v-model="editItemData.contact_name"
              :rules="[rules.required]"
              type="text"
              label="企业联系人"
              hint="请输入企业的联系人，联系人不公开仅用于经销商与企业联系使用"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '联系方式' || dlgTitle === '创建新企业'"
              v-model="editItemData.contact_phone"
              :rules="[rules.required]"
              type="text"
              label="联系电话"
              hint="请输入企业的联系电话，联系电话不公开仅用于经销商与企业联系使用"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '联系方式' || dlgTitle === '创建新企业'"
              v-model="editItemData.public_phone"
              type="text"
              label="客服电话"
              hint="公开的联系电话，可为空。"
              outlined
              dense
            ></v-text-field>
            <v-alert type="success" icon="swap_horiz" v-if="dlgTitle === '集成接口'">
              回调地址：https://cb.mdaas.com/{{editItemData.invite_code}}
            </v-alert>
            <v-select
              v-if="dlgTitle === '集成接口'"
              v-model="editItemData.apiType"
              :items="system3RD"
              item-text="name"
              item-value="code"
              label="第三方系统"
              hint="请选择第三方系统"
              outlined
              dense
            ></v-select>
            <v-text-field
              v-if="dlgTitle === '集成接口'"
              v-model="editItemData.accountId"
              type="text"
              label="第三方系统ID"
              hint="通过此ID标识与安全码生成动态令牌"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '集成接口'"
              v-model="editItemData.secureId"
              type="text"
              label="第三方系统安全码"
              hint="通过此安全码与ID标识生成动态令牌"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '企业地址' || dlgTitle === '创建新企业'"
              v-model="editItemData.address"
              :rules="[rules.required]"
              :error-messages="check.chkAddressMessage"
              :clearable="check.chkAddressMessage !== ''"
              type="text"
              label="企业地址"
              hint="请输入完整的企业地址，地址必须按正规格式输入"
              outlined
              dense
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular
                    v-if="check.chkAddressLoading"
                    width="2"
                    size="24"
                    color="secondary"
                    indeterminate
                  ></v-progress-circular>
                </v-fade-transition>
              </template>
            </v-text-field>
            <v-combobox
              v-if="dlgTitle === '下属企业'"
              v-model="editItemData.branch"
              label="下属企业"
              hint="使用回车键可以输入多个企业"
              deletable-chips
              multiple
              small-chips
              outlined
              dense
            >
            </v-combobox>
            <v-text-field
              v-if="dlgTitle === '通知方式'"
              v-model="editItemData.notice_email"
              :rules="[rules.email]"
              type="text"
              label="电子邮件"
              hint="接收通知的电子邮件信箱"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '通知方式'"
              v-model="editItemData.notice_phone"
              :rules="[rules.phonenum]"
              type="text"
              label="手机号码"
              hint="接收短信通知的手机号码"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '服务限制'"
              v-model="editItemData.limit.employees"
              type="number"
              label="员工上限"
              hint="该企业最大允许员工数量"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '服务限制'"
              v-model="editItemData.limit.assets"
              type="number"
              label="设备上限"
              hint="该企业最大允许设备数量"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-if="dlgTitle === '调整授信额度'"
              v-model="editItemData.credit_line_adjust"
              :rules="[rules.required, rules.negative]"
              :hint="editItemData.credit_line_adjust | formatMoneyUnitFloat"
              label="授信额度"
              type="number"
              persistent-hint
              outlined
              dense
              min="0"
              step="0.01"
              prefix="¥"
              suffix="元"
            ></v-text-field>
            <v-autocomplete
              v-if="dlgTitle === '客户经理'"
              v-model="editItemData.account_managers"
              :loading="accountLoading"
              :items="accountList"
              :search-input.sync="searchAccount"
              return-object
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="客户经理"
              hint="请查找并选择企业客户经理"
              multiple
            ></v-autocomplete>
            <v-autocomplete
              v-if="dlgTitle === '订单处理人'"
              v-model="editItemData.account_order"
              :loading="accountLoading"
              :items="accountList"
              :search-input.sync="searchOrderAccount"
              return-object
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              label="订单处理人"
              hint="请查找并选择企业订单处理人"
            ></v-autocomplete>
            <v-autocomplete
              v-if="dlgTitle === '订单销售人'"
              v-model="editItemData.account_sales"
              :loading="accountLoading"
              :items="accountList"
              :search-input.sync="searchSalesAccount"
              return-object
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              outlined
              dense
              label="订单销售人"
              hint="请查找并选择企业销售人员"
            ></v-autocomplete>
            <v-row v-if="dlgTitle === '创建管理员'">
              <v-col cols="12">
                <v-text-field
                  v-model="employee.editedItem.username"
                  :rules="[rules.required, rules.email]"
                  :error-messages="employee.editedItem.msgName"
                  :clearable="employee.editedItem.msgName !== ''"
                  type="text"
                  label="账号名称"
                  hint="账号名称必须为电子邮件，将作为登陆名称使用"
                  outlined
                  color="secondary"
                  dense
                  required
                >
                  <template v-slot:append>
                    <v-scale-transition leave-absolute>
                      <v-progress-circular
                        v-if="employee.editedItem.chkName"
                        :width="2"
                        size="24"
                        color="secondary"
                        indeterminate
                      ></v-progress-circular>
                    </v-scale-transition>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="employee.editedItem.phone"
                  :rules="[rules.required, rules.phonenum]"
                  :error-messages="employee.editedItem.msgPhone"
                  :clearable="employee.editedItem.msgPhone !== ''"
                  type="number"
                  label="手机号码"
                  hint="手机号码可作为登陆名称使用"
                  outlined
                  color="secondary"
                  dense
                  required
                >
                  <template v-slot:append>
                    <v-scale-transition leave-absolute>
                      <v-progress-circular
                        v-if="employee.editedItem.chkPhone"
                        :width="2"
                        size="24"
                        color="secondary"
                        indeterminate
                      ></v-progress-circular>
                    </v-scale-transition>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="employee.editedItem.password"
                  :rules="[rules.required]"
                  :type="employee.editedItem.showPass ? 'text' : 'password'"
                  :append-icon="employee.editedItem.showPass ? 'visibility_off' : 'visibility'"
                  label="账号密码"
                  hint="请牢记此密码，密码将作为登陆凭证"
                  outlined
                  color="secondary"
                  dense
                  required
                  @click:append="employee.editedItem.showPass = !employee.editedItem.showPass"
                >
                </v-text-field>
                <v-text-field
                  v-model="employee.editedItem.realname"
                  type="text"
                  label="员工姓名"
                  hint="员工姓名为用户的真实姓名"
                  outlined
                  color="secondary"
                  dense>
                </v-text-field>
                <v-text-field
                  v-model="employee.editedItem.employeeNo"
                  type="text"
                  label="员工编号"
                  hint="员工编号为企业内部的员工编号"
                  outlined
                  color="secondary"
                  dense>
                </v-text-field>
                <v-text-field
                  v-model="employee.editedItem.address"
                  :rules="[rules.required]"
                  :error-messages="employee.editedItem.msgAddress"
                  :clearable="employee.editedItem.msgAddress !== ''"
                  type="text"
                  label="收件地址"
                  hint="收件地址为接收设备的目的地"
                  outlined
                  dense
                >
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-progress-circular
                        v-if="employee.editedItem.chkAddress"
                        width="2"
                        size="24"
                        color="secondary"
                        indeterminate
                      ></v-progress-circular>
                    </v-fade-transition>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn text @click="editItem('copy_address', editItemData)">复制公司地址</v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dlgTitle === '创建库存预警' || dlgTitle === '编辑预警配置'">
              <v-col cols="6">
                <v-autocomplete
                  v-model="inventory.editedItem.vendor"
                  :items="vendorList"
                  @change="editItem('set_product_list', editItemData)"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="产品供应商"
                  hint="请选择产品供应商"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="inventory.editedItem.productType"
                  :items="typeList"
                  @change="editItem('set_product_list', editItemData)"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  clearable
                  label="产品类型"
                  hint="请选择产品类型"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  class="my-n4"
                  v-model="inventory.editedItem.product"
                  :items="productList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="关联产品"
                  hint="请选择设备关联的产品"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.name}} - {{data.item.part_number}}</v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.it_info">{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="my-n4"
                  v-model="inventory.editedItem.minimum"
                  :rules="[rules.required]"
                  type="number"
                  label="最低库存值"
                  hint="请输入库存最低值"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dlgTitle === '新增服务协议' || dlgTitle === '修改服务协议'">
              <v-col cols="12">
                <v-autocomplete
                  v-model="service.editedItem.catalog"
                  :items="flatGroupCatalog(catalogList)"
                  :rules="[rules.selected]"
                  @change="set_process_list"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  label="请选择所含服务内容"
                  outlined
                  dense
                  chips
                  small-chips
                  deletable-chips
                  multiple
                >
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-action>
                        <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="mt-n4" v-for="(element, index) in service.editedItem.process" :key="index">
                <v-autocomplete
                  v-model="element.value"
                  :items="flatGroupCatalog(catalogList).find(o => o._id === element.id).process"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  :label="flatGroupCatalog(catalogList).find(o => o._id === element.id).name+'流程'"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="mt-n4">
                <v-autocomplete
                  v-model="service.editedItem.branch"
                  :items="branchList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  chips
                  small-chips
                  deletable-chips
                  label="服务提供分支机构"
                  hint="请查找并选择提供服务的分支机构"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-menu
                  v-model="service.startMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="service.editedItem.start_time"
                      label="协议开始日期"
                      :rules="[rules.required]"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="service.editedItem.start_time" @input="service.startMenu = false" no-title scrollable></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="mt-n4">
                <v-menu
                  v-model="service.endMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="service.editedItem.end_time"
                      :rules="[rules.required]"
                      label="协议结束日期"
                      readonly
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="service.editedItem.end_time" @input="service.endMenu = false" no-title scrollable></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="mt-n4">
                <v-btn v-if="service.editedItem.sign_file" text color="secondary" @click="editItem('dl_service_file', editItemData, service.editedItem)">{{service.editedItem.sign_file.substring(service.editedItem.sign_file.lastIndexOf("/")+1)}}</v-btn>
                <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
                <input type="file" ref="uploadFile" :multiple="false" @change="detectFiles($event)" />
                <v-btn text @click="editItem('upload_service_file', editItemData, service.editedItem)">上传服务协议</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="dlgTitle === '新增年度财报' || dlgTitle === '修改年度财报'">
              <v-col cols="4">
                <v-text-field
                  v-model="earnings.editedItem.years"
                  :rules="[rules.required, rules.negative]"
                  label="年度"
                  outlined
                  dense
                  min="2000"
                  max="2100"
                  step="1"
                  type="number"
                  suffix="年"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="earnings.editedItem.scale_credit_current_assets"
                  :hint="parseFloat((earnings.editedItem.scale_credit_current_assets/100).toFixed(4)).toString()"
                  :rules="[rules.required]"
                  label="流动信用比率"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  suffix="%"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="earnings.editedItem.scale_credit_equity_owner"
                  :hint="parseFloat((earnings.editedItem.scale_credit_equity_owner/100).toFixed(4)).toString()"
                  :rules="[rules.required]"
                  label="权益信用比率"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  suffix="%"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n4">
                <v-text-field
                  v-model="earnings.editedItem.inventories"
                  :rules="[rules.required, rules.negative]"
                  :hint="earnings.editedItem.inventories | formatMoneyUnitFloat"
                  label="存货"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n4">
                <v-text-field
                  v-model="earnings.editedItem.current_assets"
                  :rules="[rules.required, rules.negative]"
                  :hint="earnings.editedItem.current_assets | formatMoneyUnitFloat"
                  label="流动资产"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n4">
                <v-text-field
                  v-model="earnings.editedItem.current_liabilities"
                  :rules="[rules.required, rules.negative]"
                  :hint="earnings.editedItem.current_liabilities | formatMoneyUnitFloat"
                  label="流动负债"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n4">
                <v-text-field
                  v-model="earnings.editedItem.total_liabilities"
                  :rules="[rules.required, rules.negative]"
                  :hint="earnings.editedItem.total_liabilities | formatMoneyUnitFloat"
                  label="负债总额"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n4">
                <v-text-field
                  v-model="earnings.editedItem.equity_owner"
                  :rules="[rules.required, rules.negative]"
                  :hint="earnings.editedItem.equity_owner | formatMoneyUnitFloat"
                  label="所有者权益合计"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="4"></v-col>
              <v-col cols="12" class="mt-n4"><v-divider></v-divider></v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="earnings.editedItem.order_amount"
                  :hint="earnings.editedItem.order_amount | formatMoneyUnitFloat"
                  label="订货额度"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="earnings.editedItem.period_credit"
                  :rules="[rules.required, rules.negative]"
                  label="标准信用帐期"
                  outlined
                  dense
                  step="1"
                  type="number"
                  suffix="天"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="earnings.editedItem.period_payment"
                  :rules="[rules.required, rules.negative]"
                  label="实际回款周期"
                  outlined
                  dense
                  step="1"
                  type="number"
                  suffix="天"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="earnings.editedItem.scale_risk_payment"
                  :hint="parseFloat((earnings.editedItem.scale_risk_payment/100).toFixed(4)).toString()"
                  :rules="[rules.required]"
                  label="回款风险评价"
                  type="number"
                  persistent-hint
                  outlined
                  dense
                  suffix="%"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n4"><v-divider></v-divider></v-col>
              <v-col cols="1" class="text-right">资产</v-col>
              <v-col cols="3" class="font-weight-bold">{{ valOperatingAssets | formatMoneyUnitFloat}}元</v-col>
              <v-col cols="2" class="text-right">评估值</v-col>
              <v-col cols="2" class="d-flex font-weight-bold">{{valEvaluation}}
                <v-btn icon x-small @click="earnings.evalDrawer= !earnings.evalDrawer">
                  <v-icon>read_more</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="2" class="text-right">评估区</v-col>
              <v-col cols="2" class="font-weight-bold" v-if="earnings.editedItem.industry">{{ valEvaluationExpe.scale }}%
                <v-btn icon x-small @click="earnings.evalDrawer= !earnings.evalDrawer">
                  <v-icon>read_more</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3" class="font-weight-bold" v-else><v-btn text class="mt-n2" @click="editItem('industry', editItemData, '所属行业')">选择行业</v-btn></v-col>
              <v-col cols="12">
                <v-alert
                  outlined
                  text
                  color="primary"
                >
                  <v-row>
                    <v-col cols="3" class="text-right">运营资产信用</v-col>
                    <v-col cols="3" class="font-weight-bold"><span :class="creditMinValue === creditOpsAsset ? 'secondary--text' : ''">{{ creditOpsAsset | formatMoneyUnitFloat}}元</span></v-col>
                    <v-col cols="3" class="text-right">流动资产信用</v-col>
                    <v-col cols="3" class="font-weight-bold"><span :class="creditMinValue === creditCurAsset ? 'secondary--text' : ''">{{ creditCurAsset | formatMoneyUnitFloat}}元</span></v-col>
                    <v-col cols="3" class="text-right">权益信用</v-col>
                    <v-col cols="3" class="font-weight-bold"><span :class="creditMinValue === creditEquity ? 'secondary--text' : ''">{{ creditEquity | formatMoneyUnitFloat}}元</span></v-col>
                    <v-col cols="3" class="text-right">销售信用</v-col>
                    <v-col cols="3" class="font-weight-bold"><span :class="creditMinValue === creditSales ? 'secondary--text' : ''">{{ creditSales | formatMoneyUnitFloat}}元</span></v-col>
                  </v-row>
                  </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="dlgTitle === '发票抬头'">
              <v-col cols="12">
                <v-subheader class="pl-0">发票信息</v-subheader>
                <v-radio-group
                  v-model="editItemData.invoice_info.invoice_type"
                  row
                >
                  <v-radio
                    label="增值税普通发票"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="增值税专用发票"
                    :value="1"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="抬头名称" dense hide-details v-model="editItemData.invoice_info.title">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="统一社会信用代码" dense hide-details v-model="editItemData.invoice_info.license_code">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="开户行" dense hide-details v-model="editItemData.invoice_info.bank_name">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="银行账号" dense hide-details v-model="editItemData.invoice_info.bank_account">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="企业地址" dense hide-details v-model="editItemData.invoice_info.license_address">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="企业电话" dense hide-details v-model="editItemData.invoice_info.license_telephone">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-subheader class="pl-0">邮寄信息</v-subheader>
                <v-text-field outlined label="收件人姓名" dense hide-details v-model="editItemData.invoice_info.delivery_name">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="收件人电话" dense hide-details v-model="editItemData.invoice_info.delivery_phone">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="收件地址" dense hide-details v-model="editItemData.invoice_info.delivery_address">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()" v-if="$route.params.id !== '0'">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-navigation-drawer v-model="earnings.evalDrawer" app bottom right hide-overlay width="316" style="z-index: 999;">
      <v-card-title>
        运营资产等级
        <v-spacer></v-spacer>
        <v-btn icon @click="earnings.evalDrawer= !earnings.evalDrawer"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="earnings.editedItem.industry">
        <v-simple-table dense fixed-header>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">评估区</th>
              <th class="text-left">评估值</th>
              <th class="text-left">评估比率</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td bgcolor="#EF9A9A">1</td>
              <td>小于 {{earnings.editedItem.industry.evaluation[0].start}}</td>
              <td>{{earnings.editedItem.industry.eval_init_scale}}%</td>
            </tr>
            <tr v-for="(scale, i) in earnings.editedItem.industry.evaluation" :key="scale._id">
              <td v-if="i <= 1" bgcolor="#EF9A9A">{{ i + 2 }}</td>
              <td v-else-if="i <= 4" bgcolor="#FFCC80">{{ i + 2 }}</td>
              <td v-else-if="i <= 7" bgcolor="#E6EE9C">{{ i + 2 }}</td>
              <td v-else bgcolor="#C5E1A5">{{ i + 2 }}</td>
              <td><span :class="scale === valEvaluationExpe ? 'secondary--text' : ''">{{ scale.start }} 至 {{ scale.end }}</span></td>
              <td><span :class="scale === valEvaluationExpe ? 'secondary--text' : ''">{{ scale.scale }}%</span></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-text v-else>
        <v-btn text @click="earnings.showIndustry = false; editItem('industry', editItemData, '所属行业')">设置企业所属行业后查看运营资产等级</v-btn>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="earnings.evalDrawer= !earnings.evalDrawer">关闭</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import Util from '@/common/util';
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import store from "@/store";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_URL,
  FETCH_ENTERPRISE,
  FETCH_ACCOUNT_LIST,
  PUBLISH_ENTERPRISE,
  EDIT_ENTERPRISE,
  CHECK_ADDR,
  FETCH_BRANCH_LIST,
  FETCH_SERVICE_LIST,
  PUBLISH_SERVICE,
  EDIT_SERVICE,
  FETCH_CATALOG_LIST,
  CHECK_USERNAME,
  CHECK_USERPHONE,
  CREATE_ADMIN,
  REMOVE_ADMIN,
  FETCH_ADMIN_LIST,
  REMOVE_SERVICE,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_VENDOR_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_EARNINGS_LIST,
  FETCH_RENT_INDUSTRY_LIST,
  PUBLISH_EARNINGS,
  FETCH_RENT_INDUSTRY, EDIT_EARNINGS, REMOVE_EARNINGS,
} from "@/store/actions.type";

export default {
  data: () => ({
    title_page: function () {
      return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? '编辑企业资料' : '注册企业信息';
    },
    editItemData: {},
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
      negative: val => ( val > 0) || '不能小于0'
    },
    valid: true,
    dlgEdit: false,
    dlgTitle: '',
    Upload: {
      uploading: false,
      progressUpload: 0,
    },
    check: {
      chkAddressMessage: '',
      chkAddressLoading: false,
    },
    searchAccount: null,
    searchOrderAccount: null,
    searchSalesAccount: null,
    inventory: {
      editedIndex: -1,
      editedItem: {
        vendor: '',
        productType: '',
        product:'',
        minimum: 0,
      },
      defaultItem: {
        vendor: '',
        productType: '',
        product:'',
        minimum: 0,
      }
    },
    earnings: {
      headers: [
        { text: '年度', value: 'years', width: '10%'},
        { text: '运营资产信用', value: 'credit.assets', width: '20%'},
        { text: '流动资产信用', value: 'credit.current_assets', width: '20%'},
        { text: '权益信用', value: 'credit.equity', width: '20%'},
        { text: '销售信用', value: 'credit.sales', width: '20%'},
        { text: '操作', value: 'actions', sortable: false, width: '10%'},
      ],
      editedIndex: -1,
      editedItem: {
        years: new Date().getFullYear(),
        inventories: 0.00,
        current_assets: 0.00,
        current_liabilities: 0.00,
        total_liabilities: 0.00,
        equity_owner: 0.00,
        order_amount: 0.00,
        period_credit: 0,
        period_payment: 0,
        industry: '',
        scale_credit_current_assets: 20,
        scale_credit_equity_owner: 20,
        scale_risk_payment: 10,
      },
      defaultItem: {
        years: new Date().getFullYear(),
        inventories: 10000,
        current_assets: 100000,
        current_liabilities: 50000,
        total_liabilities: 50000,
        equity_owner: 150000,
        order_amount: 0,
        period_credit: 30,
        period_payment: 30,
        industry: '',
        scale_credit_current_assets: 20,
        scale_credit_equity_owner: 10,
        scale_risk_payment: 10,
      },
      evalDrawer: false,
    },
    service: {
      headers: [
        { text: '服务内容', value: 'catalog', width: '28%'},
        { text: '服务门店', value: 'branch', width: '28%'},
        { text: '开始时间', value: 'start_time', width: '13%'},
        { text: '结束时间', value: 'end_time', width: '23%'},
        { text: '操作', value: 'actions', sortable: false, width: '8%'},
      ],
      startMenu: false,
      endMenu: false,
      editedIndex: -1,
      processList: [],
      editedItem: {
        catalog: [],
        branch: [],
        sign_file: '',
        start_time: '',
        end_time: '',
        process: []
      },
      defaultItem: {
        catalog: [],
        branch: [],
        sign_file: '',
        start_time: '',
        end_time: '',
        process: []
      }
    },
    employee: {
      editedItem: {
        showPass: false,
        chkName: false,
        chkPhone: false,
        chkAddress: false,
        msgName: '',
        msgPhone: '',
        msgAddress: '',
        username: '',
        password: '',
        phone: '',
        realname: '',
        address: '',
        employeeNo: ''
      },
      defaultItem: {
        showPass: false,
        chkName: false,
        chkPhone: false,
        chkAddress: false,
        msgName: '',
        msgPhone: '',
        msgAddress: '',
        username: '',
        password: '',
        phone: '',
        realname: '',
        address: '',
        employeeNo: ''
      }
    },
    receipt: {
      title: '',
      invoice_type: 0,
      license_code: '',
      bank_name: '',
      bank_account: '',
      license_address: '',
      license_telephone: '',
      delivery_address: '',
      delivery_name: '',
      delivery_phone: '',
    }
  }),
  watch: {
    searchAccount (val) {
      val && val !== this.editItemData.account_managers && this.searchEmployee(val)
    },
    searchOrderAccount (val) {
      val && val !== this.editItemData.account_order && this.searchEmployee(val)
    },
    searchSalesAccount (val) {
      val && val !== this.editItemData.account_sales && this.searchEmployee(val)
    },
    'editItemData.address'() {
      this.checkAddress();
    },
    dlgEdit (val) {
      val || this.close()
    },
    'employee.editedItem.username'() {
      this.checkName();
    },
    'employee.editedItem.phone'() {
      this.checkPhone();
    },
    'employee.editedItem.address'() {
      this.checkAddressAccount();
    },
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "enterprise",
      "uploadSign",
      "accountLoading",
      "accountList",
      "branchList",
      "serviceList",
      "catalogList",
      "adminList",
      "vendorList",
      "typeList",
      "productList",
      "earningsList",
      "rentIndustryList"]),
    inputTypedef () {
      return Util.categories('corpType')
    },
    system3RD() {
      return Util.categories('system3RD')
    },
    valOperatingAssets() {
      let s1 = parseFloat(this.earnings.editedItem.current_assets) - parseFloat(this.earnings.editedItem.current_liabilities)
      s1 += parseFloat(this.earnings.editedItem.equity_owner)
      return s1 / 2
    },
    valEvaluation() {
      let s1 = parseFloat(this.earnings.editedItem.current_assets) / parseFloat(this.earnings.editedItem.current_liabilities)
      let s2 = (parseFloat(this.earnings.editedItem.current_assets) - parseFloat(this.earnings.editedItem.inventories)) / parseFloat(this.earnings.editedItem.current_liabilities)
      let s3 = parseFloat(this.earnings.editedItem.current_liabilities) / parseFloat(this.earnings.editedItem.equity_owner)
      let s4 = parseFloat(this.earnings.editedItem.total_liabilities) / parseFloat(this.earnings.editedItem.equity_owner)
      return (s1 + s2 - s3 - s4).toFixed(2)
    },
    valEvaluationExpe() {
      let sysEval = this.valEvaluation
      if (this.earnings.editedItem.industry.evaluation && this.earnings.editedItem.industry.evaluation.length) {
        if (sysEval < this.earnings.editedItem.industry.evaluation[0].start) return {scale: this.earnings.editedItem.industry.eval_init_scale}
        if (sysEval >= this.earnings.editedItem.industry.evaluation[this.earnings.editedItem.industry.evaluation.length - 1].start) return this.earnings.editedItem.industry.evaluation[this.earnings.editedItem.industry.evaluation.length - 1]
        let objRow = this.earnings.editedItem.industry.evaluation.find((item) => sysEval >= item.start && sysEval <=item.end)
        return objRow
      } else {
        return {}
      }
    },
    creditOpsAsset() {
      return (parseFloat(this.valOperatingAssets * (this.valEvaluationExpe.scale/100)/1000)).toFixed(2)
    },
    creditCurAsset() {
      let scale_credit_current_assets = parseFloat(this.earnings.editedItem.scale_credit_current_assets)/100
      return (parseFloat(this.earnings.editedItem.current_assets) * scale_credit_current_assets / 1000).toFixed(2)
    },
    creditEquity() {
      let scale_credit_equity_owner = parseFloat(this.earnings.editedItem.scale_credit_equity_owner)/100
      return (parseFloat(this.earnings.editedItem.equity_owner) * scale_credit_equity_owner / 1000).toFixed(2)
    },
    creditSales() {
      let scale_period = parseFloat(this.earnings.editedItem.period_credit / this.earnings.editedItem.period_payment).toFixed(4)
      return (parseFloat(this.earnings.editedItem.order_amount * scale_period * (1 - this.earnings.editedItem.scale_risk_payment/100))).toFixed(2)
    },
    creditMinValue() {
      return Math.min(this.creditOpsAsset, this.creditCurAsset, this.creditEquity, this.creditSales).toFixed(2)
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_PRODUCTTYPE_LIST, 'isEnable=true'),
      store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_RENT_INDUSTRY_LIST, 'isEnable=true&limit=200'),
      store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=100'),
    ]).then(() => {
      next();
    });
  },
  created () {
    if (this.$route.params.id !== '0') {
      this.getEnterprise(this.$route.params.id);
      this.$store.dispatch(FETCH_ACCOUNT_LIST)
    } else {
      this.editItem('create_enterprise', {}, '创建新企业')
    }
  },
  methods: {
    goBack() {
      this.editItemData = Object.assign({}, {})
      this.$router.push({path: '/dealer/enterprise'})
    },
    getEnterprise(id) {
      this.$store.dispatch(FETCH_ENTERPRISE, id)
        .then(data => {
          store.dispatch(FETCH_BRANCH_LIST)
          store.dispatch(FETCH_CATALOG_LIST)
          store.dispatch(FETCH_SERVICE_LIST, id)
          store.dispatch(FETCH_ADMIN_LIST, id)
          if (this.currentUser.hasAdmin.includes('dealer') ||
            this.currentUser.permission.includes('Lease_Product') ||
            this.currentUser.permission.includes('Lease_Admin') ||
            this.currentUser.permission.includes('Lease_Audit')) store.dispatch(FETCH_EARNINGS_LIST, id)
          store.dispatch(FETCH_ACCOUNT_LIST)
          this.editItemData = Object.assign({}, data)
          if (!this.editItemData.invoice_info) {
            this.receipt.title = this.editItemData.name
            this.receipt.license_code = this.editItemData.license_key || ''
            this.editItemData.invoice_info = this.receipt
          }
          if (!this.editItemData.additional) {
            this.editItemData.additional = {
              abm: '', ace: '', esc: ''
            }
          }
          this.editItemData._id = data._id
        });
    },
    close (enterprise = '') {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.earnings.evalDrawer = false
      this.getEnterprise(enterprise ? enterprise : this.$route.params.id)
      this.$nextTick(() => {
        this.employee.editedItem = Object.assign({}, this.employee.defaultItem)
        this.service.editedItem = Object.assign({}, this.service.defaultItem)
        this.service.editedIndex = -1
        this.inventory.editedItem = Object.assign({}, this.inventory.defaultItem)
        this.earnings.editedItem = Object.assign({}, this.earnings.defaultItem)
      })
    },
    searchEmployee (key) {
      this.$store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    editItem(action, item, customitem) {
      this.editItemData = Object.assign({}, item)
      switch (action) {
        case 'upload_profile': {
          this.dlgTitle = '编辑横幅';
          this.$store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadInput.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'upload_logo': {
          this.dlgTitle = '编辑标示';
          this.$store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadInput.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'set_product_list': {
          let strQuery = 'isEnable=true&limit=1000'
          if (this.inventory.editedItem.vendor) strQuery += '&vendor='+ this.inventory.editedItem.vendor
          if (this.inventory.editedItem.productType) strQuery += '&type='+ this.inventory.editedItem.productType
          store.dispatch(FETCH_PRODUCT_LIST, strQuery)
          break
        }
        case 'upload_service_file': {
          this.$store.dispatch(FETCH_UPLOAD, 'private')
            .then(this.$refs.uploadFile.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'dl_service_file': {
          this.$store.dispatch(FETCH_URL, customitem.sign_file)
            .then((fileurl) => {
              window.location.href = fileurl;
            })
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'self_register': case 'self_asset': case 'allow_gongbei': {
          this.submit()
          break
        }
        case 'create_service': case 'edit_service': {
          this.service.editedIndex = this.serviceList.indexOf(customitem)
          this.service.editedItem = Object.assign(this.service.defaultItem, customitem)
          if (this.service.editedItem.start_time) this.service.editedItem.start_time = moment(this.service.editedItem.start_time).format('YYYY-MM-DD');
          if (this.service.editedItem.end_time) this.service.editedItem.end_time = moment(this.service.editedItem.end_time).format('YYYY-MM-DD');
          if (this.service.editedItem.branch) this.service.editedItem.branch = this.service.editedItem.branch.map(data => data._id);
          if (this.service.editedItem.catalog) this.service.editedItem.catalog = this.service.editedItem.catalog.map(data => data._id);
          this.service.editedItem.enterprise = item._id
          this.service.editedItem.dealer = item.account_dealer
          this.dlgTitle = this.service.editedIndex === -1 ? '新增服务协议' : '修改服务协议'
          this.dlgEdit = true
          break
        }
        case 'create_earnings': case 'edit_earnings': {
          this.earnings.editedIndex = this.earningsList.indexOf(customitem)
          this.earnings.editedItem = Object.assign({}, this.earnings.defaultItem)
          if (item.industry && item.industry._id) {
            store.dispatch(FETCH_RENT_INDUSTRY, item.industry._id)
              .then((data) => {
                this.earnings.editedItem.industry = JSON.parse(JSON.stringify(data))
              })
          }
          if (this.earnings.editedIndex === -1) {
            this.dlgTitle = '新增年度财报'
          } else {
            this.dlgTitle = '修改年度财报'
            this.earnings.editedItem._id = customitem._id;
            this.earnings.editedItem.years = customitem.years;
            this.earnings.editedItem.inventories = customitem.finance.inventories;
            this.earnings.editedItem.current_assets = customitem.finance.current_assets["$numberDecimal"];
            this.earnings.editedItem.current_liabilities = customitem.finance.current_liabilities["$numberDecimal"];
            this.earnings.editedItem.total_liabilities = customitem.finance.total_liabilities["$numberDecimal"];
            this.earnings.editedItem.equity_owner = customitem.finance.total_equity_owners["$numberDecimal"];
            this.earnings.editedItem.order_amount = customitem.business.order_amount["$numberDecimal"];
            this.earnings.editedItem.period_credit = customitem.business.period_credit;
            this.earnings.editedItem.period_payment = customitem.business.period_payment;
            this.earnings.editedItem.scale_credit_current_assets = customitem.finance.scale_current * 100;
            this.earnings.editedItem.scale_credit_equity_owner = customitem.finance.scale_equity * 100;
            this.earnings.editedItem.scale_risk_payment = customitem.business.scale_risk_payment * 100;
          }
          this.dlgEdit = true
          break
        }
        case 'delete_earnings': {
          if (confirm('确定要删除 '+ customitem.years +' 的财务报表？')) {
            store.dispatch(REMOVE_EARNINGS, customitem._id)
              .then(() => {
                this.getEnterprise(item._id)
                store.commit(SET_ERROR, {msg: '删除成功。', color: 'primary'});
              })
              .catch((err) => {
                store.commit(SET_ERROR, {msg: err});
              });
          }
          break
        }
        case 'copy_address': {
          this.employee.editedItem.address = item.address
          break
        }
        case 'remove_admin': {
          if (confirm('确定要移除 '+ customitem.username +' 管理权限，移除后该账号将失去对企业的管理功能。')) {
            this.$store.dispatch(REMOVE_ADMIN, { ownerid: item._id, userid: customitem._id, type: 'Enterprise' })
              .then(() => {
                store.dispatch(FETCH_ADMIN_LIST, item._id)
                this.$store.commit(SET_ERROR, {msg: '移除成功。', color: 'primary'});
              })
              .catch((err) => {
                this.$store.commit(SET_ERROR, {msg: err});
              });
          }
          break
        }
        case 'remove_warning': {
          if (confirm('确定要移除 '+ customitem.product.name +' 库存预警，移除后该产品将不再进行预警统计。')) {
            this.editItemData.inventory_warning.splice(this.editItemData.inventory_warning.indexOf(customitem), 1)
            Promise.all(this.editItemData.inventory_warning.map(item => {
              item.product = item.product._id
            }))
            this.submit()
          }
          break
        }
        case 'edit_warning': {
          let proName = customitem.product.name
          this.dlgTitle = '编辑预警配置'
          if (proName) {
            proName = proName.replace('[仅限维修使用]', '').replace('/', '//')
          }
          store.dispatch(FETCH_PRODUCT_LIST, 'key='+ proName +'&isEnable=true&limit=100'),
          this.inventory.editedItem = Object.assign({}, customitem)
          this.dlgEdit = true
          break
        }
        case 'delete_service': {
          if (!moment().isBefore(customitem.end_time)) {
            if (confirm('确定要删除指定服务？')) {
              this.$store.dispatch(REMOVE_SERVICE, customitem)
                .then(() => {
                  store.dispatch(FETCH_SERVICE_LIST, customitem.enterprise)
                  this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'});
                })
                .catch((err) => {
                  this.$store.commit(SET_ERROR, {msg: err});
                });
            }
          } else {
            this.$store.commit(SET_ERROR, {msg: '不能删除未结束的服务协议'});
          }

          break
        }
        case 'to_employer': {
          this.$router.push({path: '/dealer/employee?enterprise='+ item._id})
          break
        }
        case 'to_asset': {
          this.$router.push({path:'/dealer/asset/list?enterprise='+ item._id})
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      if (this.dlgTitle === '新增服务协议' || this.dlgTitle === '修改服务协议') {
        param.append('key', 'service/'+ this.editItemData._id +'/'+ this.uploadSign.key + this.fileName.substring(this.fileName.lastIndexOf(".")));
      } else {
        param.append('key', 'enterprise/'+ this.editItemData._id +'/'+ this.uploadSign.key);
      }
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          if (this.dlgTitle === '编辑横幅') {
            this.$refs.uploadInput.value = '';
            this.editItemData.profile_image = 'enterprise/'+ this.editItemData._id +'/'+ this.uploadSign.key;
            this.submit();
          }
          if (this.dlgTitle === '编辑标示') {
            this.$refs.uploadInput.value = '';
            this.editItemData.logo_image = 'enterprise/'+ this.editItemData._id +'/'+ this.uploadSign.key;
            this.submit();
          }
          if (this.dlgTitle === '新增服务协议' || this.dlgTitle === '修改服务协议') {
            this.$refs.uploadFile.value = '';
            this.service.editedItem.sign_file = 'service/'+ this.editItemData._id +'/'+ this.uploadSign.key + this.fileName.substring(this.fileName.lastIndexOf("."));
          }
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    set_process_list() {
      this.service.editedItem.process.length = 0
      this.service.editedItem.catalog.forEach(item => {
        this.service.editedItem.process.push({
          id: item,
          value: ''
        })
      })
    },
    submit() {
      this.earnings.evalDrawer = false;
      if (this.editItemData._id) {
        switch (this.dlgTitle) {
          case '新增服务协议': case '修改服务协议': {
            if (this.service.editedIndex > -1) {
              this.$store.dispatch(EDIT_SERVICE, this.service.editedItem)
                .then((data) => {
                  store.dispatch(FETCH_SERVICE_LIST, data.enterprise)
                  store.commit(SET_ERROR, {msg: '成功修改服务协议', color: 'primary'});
                  this.close()
                })
                .catch((err) => {
                  this.$store.commit(SET_ERROR, {msg: err.response.data.message});
                });
            } else {
              this.$store.dispatch(PUBLISH_SERVICE, this.service.editedItem)
                .then((data) => {
                  store.dispatch(FETCH_SERVICE_LIST, data.enterprise)
                  store.commit(SET_ERROR, {msg: '成功新增服务协议', color: 'primary'});
                  this.close()
                })
                .catch((err) => {
                  this.$store.commit(SET_ERROR, {msg: err.response.data.message});
                });
            }
            this.close()
            break
          }
          case '创建管理员': {
            let register = {}
            register.personal = {}
            register.ownerType = 'Enterprise'
            register.ownerId = this.editItemData._id
            register.username = this.employee.editedItem.username
            register.password = this.employee.editedItem.password
            register.phone = this.employee.editedItem.phone
            register.employeeNo = this.employee.editedItem.employeeNo
            register.personal.name = this.employee.editedItem.realname
            register.address = this.employee.editedItem.address
            if (this.employee.editedItem.addressInfo) {
              register.addressInfo = this.employee.editedItem.addressInfo
            } else {
              this.$store.commit(SET_ERROR, {msg: '请等待地址验证中...', color: 'secondary', timeout: 500});
              return
            }

            this.$store.dispatch(CREATE_ADMIN, register)
              .then(() => {
                this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
                store.dispatch(FETCH_ADMIN_LIST, register.ownerId)
                this.close()
              })
              .catch((err) => {
                this.$store.commit(SET_ERROR, {msg: err.response.data.message});
              });
            break
          }
          case '新增年度财报': case '修改年度财报': {
            let strPost = {};
            strPost.finance = {};
            strPost.business = {};
            strPost.enterprise = this.editItemData._id
            strPost.years = parseInt(this.earnings.editedItem.years)
            strPost.finance.inventories = this.earnings.editedItem.inventories
            strPost.finance.current_assets = this.earnings.editedItem.current_assets
            strPost.finance.current_liabilities = this.earnings.editedItem.current_liabilities
            strPost.finance.total_liabilities = this.earnings.editedItem.total_liabilities
            strPost.finance.total_equity_owners = this.earnings.editedItem.equity_owner
            strPost.finance.scale_current = parseFloat(this.earnings.editedItem.scale_credit_current_assets / 100).toFixed(4)
            strPost.finance.scale_equity = parseFloat(this.earnings.editedItem.scale_credit_equity_owner / 100).toFixed(4)

            strPost.business.order_amount = this.earnings.editedItem.order_amount
            strPost.business.period_credit = this.earnings.editedItem.period_credit
            strPost.business.period_payment = this.earnings.editedItem.period_payment
            strPost.business.scale_risk_payment = parseFloat(this.earnings.editedItem.scale_risk_payment / 100).toFixed(4)
            if (this.earnings.editedItem._id) {
              strPost._id = this.earnings.editedItem._id
              store.dispatch(EDIT_EARNINGS, strPost)
                .then((data) => {
                  store.dispatch(FETCH_EARNINGS_LIST, data.enterprise)
                  store.commit(SET_ERROR, {msg: '成功修改企业财报', color: 'primary'});
                  this.close()
                })
                .catch((err) => {
                  store.commit(SET_ERROR, {msg: err.data.message});
                });
            } else {
              store.dispatch(PUBLISH_EARNINGS, strPost)
                .then((data) => {
                  store.dispatch(FETCH_EARNINGS_LIST, data.enterprise)
                  store.commit(SET_ERROR, {msg: '成功创建企业财报', color: 'primary'});
                  this.close()
                })
                .catch((err) => {
                  store.commit(SET_ERROR, {msg: err.data.message});
                });
            }
            break
          }
          default: {
            delete this.editItemData['creater']
            if (this.inventory.editedItem.product) {
              if (this.inventory.editedItem._id) {
                let editIndex = this.editItemData.inventory_warning.findIndex(o => o._id === this.inventory.editedItem._id)
                Object.assign(this.editItemData.inventory_warning[editIndex], this.inventory.editedItem)
                Promise.all(this.editItemData.inventory_warning.map(item => {
                  item.product = item.product._id
                }))
              } else {
                if (!this.editItemData.inventory_warning) this.editItemData.inventory_warning = []
                this.editItemData.inventory_warning.push({
                  product: this.inventory.editedItem.product,
                  minimum: this.inventory.editedItem.minimum
                })
              }
            }
            this.$store.dispatch(EDIT_ENTERPRISE, this.editItemData)
              .then(({data}) => {
                this.close()
                this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getEnterprise(data._id);
              })
              .catch((err) => {
                this.$store.commit(SET_ERROR, {msg: err.response.data.message});
              });
            break
          }
        }
      } else {
        store.dispatch(PUBLISH_ENTERPRISE, this.editItemData)
          .then(({data}) => {
            store.commit(SET_ERROR, {msg: '创建成功，请继续补充企业资料。', color: 'primary'});
            this.getEnterprise(data._id);
            this.close(data._id)
            this.$router.push({path: '/dealer/enterprise/edit/' + data._id});
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
    },
    getTypeText(code) {
      return this.inputTypedef.find(item => item.code === code)
    },
    checkAddress: _.debounce(function () {
      if (this.editItemData && this.editItemData.address) {
        this.check.chkAddressLoading = true;
        this.$store.dispatch(CHECK_ADDR, this.editItemData.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.check.chkAddressMessage = '地址信息错误，请输入正确的地址信息';
            } else {
              this.editItemData.addressInfo = data.result;
              this.check.chkAddressMessage = '';
            }
            this.check.chkAddressLoading = false;
          })
          .catch((err) => {
            this.check.chkAddressMessage = err.data.message;
            this.check.chkAddressLoading = false;
          })
      }
    }, 500),
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, group: groupItem.name, process: catalogItem.process })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
    diffDays(endTime) {
      let nowTime = moment();
      return nowTime.diff(endTime, 'hours')
    },
    checkName: _.debounce(function () {
      if (this.employee.editedItem.username) {
        this.employee.editedItem.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.employee.editedItem.username)
          .then(() => {
            this.employee.editedItem.msgName = '账号已被注册，请更换其它名称';
            this.employee.editedItem.chkName = false;
          })
          .catch(() => {
            this.employee.editedItem.msgName = '';
            this.employee.editedItem.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.employee.editedItem.phone) {
        this.employee.editedItem.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.employee.editedItem.phone)
          .then(() => {
            this.employee.editedItem.msgPhone = '手机号码已被注册，请更换其它名称';
            this.employee.editedItem.chkPhone = false;
          })
          .catch(() => {
            this.employee.editedItem.msgPhone = '';
            this.employee.editedItem.chkPhone = false;
          })
      }
    }, 1000),
    checkAddressAccount: _.debounce(function () {
      if (this.employee.editedItem.address) {
        this.employee.editedItem.chkAddress = true;
        this.$store.dispatch(CHECK_ADDR, this.employee.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.employee.editedItem.msgAddress = '地址信息错误，请输入正确的地址信息';
            } else {
              this.employee.editedItem.addressInfo = data.result;
              this.employee.editedItem.msgAddress = '';
            }
            this.employee.editedItem.chkAddress = false;
          })
          .catch((err) => {
            this.employee.editedItem.msgAddress = err.data.message;
            this.employee.editedItem.chkAddress = false;
          })
      }
    }, 500),
    copyCode() {
      return this.$el.querySelector('#copyTarget')
    },
    copyID() {
      return this.$el.querySelector('#copyId')
    }
  }
}
</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0,0,0,0);
  }
</style>
